type Platform = "web" | "electron";

export const platform: Platform = (function () {
  if (typeof window !== "undefined" && window.isElectron) return "electron";
  return "web";
})();

export const supportsCot = platform !== "web";

export const supportsUdp = platform !== "web";
